// SeasonSelector.js

import React, { useState } from 'react';

const SeasonSelector = ({ onSeasonSelect }) => {
  const [selectedSeason, setSelectedSeason] = useState('');

  const handleSeasonChange = (event) => {
    setSelectedSeason(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSeasonSelect(selectedSeason);
  };

  return (
    <div>
      <h2>Select a Season</h2>
      <form onSubmit={handleSubmit}>
        <select value={selectedSeason} onChange={handleSeasonChange}>
          <option value="">Choose a season</option>
          {/* <option value="fall">Fall</option> */}
          <option value="summer">Summer</option>
        </select>
        <button type="submit" disabled={!selectedSeason}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default SeasonSelector;